.investor {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.investor-container {
  align-self: center;
  max-width: 1444px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 60px;
  min-height: 60vh;
  gap: 100px;

  @include e(one) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    @include e(text) {
      max-width: 70%;
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
  @include e(two) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    @include e(text) {
      max-width: 70%;
      @include e(buttons) {
        margin-top: 20px;
        width: 100%;
        gap: 50px;
        display: flex;
        flex-direction: row;
      }
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
  @include e(three) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 50px;
    @include e(text) {
      max-width: 70%;
      @include e(buttons) {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
}
.download-icon {
  max-width: 24px;
}
.metrics-link {
  & a {
    display: flex;
    flex-direction: row;
    max-width: fit-content;
    gap: 10px;
    white-space: nowrap;
    color: #f84c4c;
    font-size: 22px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.242px;
    text-decoration-line: underline;
    & img {
      max-width: 24px;
      align-self: center;
    }
  }
}
.download-button {
  display: flex;
  flex-direction: column;
  gap: 10px;
  & a {
    color: #fff;
    font-family: Noto Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.242px;
    min-width: fit-content;
    max-width: 450px;
    text-decoration: none;
    padding: 10px;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
    border-radius: 10px;
    background: #f84c4c;
  }
}
@media (max-width: 1000px) {
  .investor-container {
    @include e(one) {
      flex-direction: column;
      @include e(text) {
        max-width: 100%;
      }
      @include e(image) {
        align-self: center;
      }
    }
    @include e(two) {
      flex-direction: column-reverse;
      @include e(text) {
        max-width: 100%;
      }
      @include e(image) {
        align-self: center;
      }
    }
    @include e(three) {
      flex-direction: column;
      @include e(text) {
        max-width: 100%;
      }
      @include e(image) {
        align-self: center;
      }
    }
  }
}
@media (max-width: 500px) {
  .investor-container {
    padding: 20px 20px;
    @include e(two) {
      @include e(text) {
        @include e(buttons) {
          width: 100%;
          gap: 20px;
          flex-direction: column;
        }
      }
    }
  }
  .metrics-link {
    & a {
      font-size: 14px;
      width: 100%;
    }
  }
  .download-button {
    & a {
      font-size: 14px;
      min-width: fit-content;
      width: 100%;
    }
  }
  .download-icon {
    max-width: 20px;
  }
}
